import styled from '@emotion/styled';
import { Menu, MenuButton, MenuItems, MenuLink, MenuPopover } from '@reach/menu-button';
import useScrollBlock from '@s/hooks/useScrollBlock';
import { Link } from 'gatsby';
import { Fragment, useEffect, useState } from 'react';
import { NavLinksTypes, subNavLinks } from './NavLinks';
import { navLinkStyles, StyledNavBtn, subNavLinkStyles } from './NavLinkStyles';

const StyledMenuPopover = styled(MenuPopover)`
    &[data-reach-menu-popover] {
        padding: 32px 64px;
        background-color: ${({ theme }) => theme.colors.light};
        z-index: 100;
        transition: transform 0.6s cubic-bezier(0.64, 0.57, 0.37, 1.33);
        border-bottom: 4px solid ${({ theme }) => theme.colors.accent};
        border-radius: 32px;

        &[hidden] {
            &.desktop-nav-dropdown-not-expanded {
                display: none;
            }
            &.desktop-nav-dropdown-expanded {
                transform: translateY(-1000px);
                display: block;
            }
        }

        :not([hidden]) {
            &.desktop-nav-dropdown-not-expanded {
                transform: translateY(-1000px);
                display: block;
            }
            &.desktop-nav-dropdown-expanded {
                transform: translateY(21px);
            }
        }
    }

    [data-reach-menu-items] {
        display: grid;
        grid-template-rows: repeat(8, auto);
        gap: 16px 32px;
        justify-items: start;
        grid-auto-flow: column;

        /* reset */
        padding: 0;
        outline: none;
        border: none;
        background: none;
    }

    [data-reach-menu-link] {
        ${navLinkStyles};
        ${subNavLinkStyles};
    }
`;

const Bg = styled.div<{ isExpanded: boolean; expanded: boolean }>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.25);
    transition: opacity 0.3s ease-in-out;
    z-index: 90;
    display: ${({ isExpanded, expanded }) => (isExpanded || expanded ? 'block' : 'none')};
    opacity: ${({ isExpanded, expanded }) => (expanded ? (isExpanded ? 1 : 0) : 0)};
`;

type StyledPopoverProps = {
    isExpanded: boolean;
    links: NavLinksTypes;
    navId: number;
};

const StyledPopover = ({ isExpanded, links, navId }: StyledPopoverProps) => {
    const [expanded, setExpanded] = useState(isExpanded);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;

        if (isExpanded) {
            timeoutId = setTimeout(() => setExpanded(true), 10);
        } else if (!isExpanded) {
            timeoutId = setTimeout(() => setExpanded(false), 300);
        }
        return () => {
            clearTimeout(timeoutId);
        };
    }, [isExpanded, links.length]);

    useScrollBlock(expanded);

    return (
        <Fragment>
            <StyledMenuPopover
                className={
                    expanded ? 'desktop-nav-dropdown-expanded' : 'desktop-nav-dropdown-not-expanded'
                }
            >
                <MenuItems as="nav" aria-labelledby={`menu-button--menu--${navId}`}>
                    {links.map((link, i) => (
                        <MenuLink
                            as={Link}
                            key={i}
                            to={link.link}
                            activeClassName="nav-link-current-page"
                        >
                            {link.text}
                        </MenuLink>
                    ))}
                </MenuItems>
            </StyledMenuPopover>
            <Bg isExpanded={isExpanded} expanded={expanded} />
        </Fragment>
    );
};

type DesktopSubNavProps = { text: string; category: string; navId: number };

export const DesktopSubNav = ({ text, category, navId }: DesktopSubNavProps) => {
    return (
        <Menu>
            {({ isExpanded }) => (
                <Fragment>
                    <StyledNavBtn
                        className={category === text ? 'nav-link-current-page' : ''}
                        id={`menu-button--menu--${navId}`}
                        as={MenuButton}
                    >
                        {text}
                    </StyledNavBtn>

                    <StyledPopover
                        isExpanded={isExpanded}
                        links={subNavLinks[text]}
                        navId={navId}
                    />
                </Fragment>
            )}
        </Menu>
    );
};
