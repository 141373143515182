import { AppointmentForm } from '@/AppointmentForm';
import { ContactForm } from '@/ContactForm';
import { ContactHours } from '@/ContactHours';
import { flexCenter } from '@/layout/styles/classes';
import styled from '@emotion/styled';
import useLocationData from '@s/hooks/useLocationData';
import { Fragment } from 'react';

const ContactSection = styled.section`
    @media (min-width: 1024px) {
        ${flexCenter};
        align-items: flex-start;

        > div {
            width: 50%;
        }
    }
`;

export const ContactAppointment = () => {
    const { category } = useLocationData();

    if (category === 'contact us') return <Fragment />;
    else
        return (
            <ContactSection>
                <ContactHours />

                <div>{category === 'contact us' ? <ContactForm /> : <AppointmentForm />}</div>
            </ContactSection>
        );
};
