import { IconBtn, PlainBtn } from '@/buttons';
import CLink from '@/CLink';
import phone from '@a/icons/phone.svg';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import useEventListener from '@s/hooks/useEventListener';
import useRouteChange from '@s/hooks/useRouteChange';
import useScrollBlock from '@s/hooks/useScrollBlock';
import useWindowSize from '@s/hooks/useWindowSize';
import FocusTrap from 'focus-trap-react';
import { Dispatch, Fragment, SetStateAction } from 'react';
import { MobSubNav } from './MobSubNav';
import { info, navLinks } from './NavLinks';
import { navLinkStyles } from './NavLinkStyles';
import { flexSpace } from './styles/classes';

const NavSlider = styled.div<{ navOpen: boolean; navVisibility: boolean }>`
    position: ${({ navOpen }) => (navOpen ? 'fixed' : 'absolute')};
    left: 0;
    top: ${({ navOpen }) => (navOpen ? '76px' : 'calc(-100vh + 156px)')};
    height: calc(100% - 76px);
    width: 100vw;
    z-index: 90;
    overflow: hidden;
    background-color: ${({ theme }) => theme.colors.white};
    transition: top 0.3s ease-in-out;
    padding: 32px;
    border-top: 1px solid #e5e5e5;
    border-bottom: 4px solid ${({ theme }) => theme.colors.accent};

    > nav {
        ${flexSpace};
        flex-direction: column;
        height: 100%;
        max-height: 450px;
        margin: 0 auto;
        visibility: ${({ navVisibility }) => (navVisibility ? 'visible' : 'hidden')};
    }

    @media (min-width: 1024px) {
        display: none;
    }
`;

const Btn = styled(PlainBtn)<{ navOpen: boolean }>`
    display: grid;

    > svg {
        transition: opacity 0.3s ease-in-out;
        grid-area: 1/1;

        path {
            transition: stroke 0.3s ease-in-out;
        }
    }

    :hover {
        > svg > path {
            stroke: ${({ theme }) => theme.colors.accentDark};
        }
    }

    :focus-visible {
        > svg > path {
            stroke: ${({ theme }) => theme.colors.accentDark};
        }
    }

    ${({ navOpen }) =>
        navOpen
            ? css`
                  > svg {
                      :first-of-type {
                          opacity: 0;
                      }

                      :last-of-type {
                          opacity: 1;
                      }
                  }
              `
            : css`
                  > svg {
                      :first-of-type {
                          opacity: 1;
                      }

                      :last-of-type {
                          opacity: 0;
                      }
                  }
              `};

    @media (min-width: 1024px) {
        display: none;
    }
`;

type MobNavProps = {
    setNavOpen: Dispatch<SetStateAction<boolean>>;
    navOpen: boolean;
    navVisibility: boolean;
    closeNav: () => void;
    category: string;
};

export const MobNav = ({ setNavOpen, navOpen, navVisibility, closeNav, category }: MobNavProps) => {
    useScrollBlock(navOpen);

    const { width } = useWindowSize();

    useRouteChange(setNavOpen);

    const handleEsc = (e: KeyboardEvent) => {
        const key = e.key || e.keyCode;
        if ((key === 'Escape' || key === 'Esc' || key === 27) && navOpen) {
            closeNav();
        }
    };

    useEventListener('keydown', handleEsc);

    return width < 1024 ? (
        <Fragment>
            <FocusTrap
                active={navOpen}
                focusTrapOptions={{
                    allowOutsideClick: true,
                    initialFocus: false,
                }}
            >
                <NavSlider navOpen={navOpen} navVisibility={navVisibility}>
                    <nav>
                        {navLinks.map((link, i) =>
                            link.link === 'submenu' ? (
                                <MobSubNav
                                    text={link.text}
                                    key={i}
                                    closeNav={closeNav}
                                    category={category}
                                    navId={i}
                                />
                            ) : (
                                <CLink
                                    to={link.link}
                                    css={navLinkStyles}
                                    activeClassName="nav-link-current-page"
                                    key={i}
                                >
                                    {link.text}
                                </CLink>
                            )
                        )}

                        <IconBtn as={CLink} to={info.phone.link} aria-label={info.phone.text}>
                            <img src={phone} alt="phone" width={20} height={28} />
                        </IconBtn>
                    </nav>
                </NavSlider>
            </FocusTrap>
        </Fragment>
    ) : (
        <Fragment />
    );
};

type MobNavBtnProps = {
    toggleNav: () => void;
    navOpen: boolean;
};
export const MobNavBtn = ({ toggleNav, navOpen }: MobNavBtnProps) => (
    <Btn
        onClick={toggleNav}
        navOpen={navOpen}
        aria-label={`${navOpen ? 'close' : 'open'} navigation menu`}
    >
        <svg
            width="30"
            height="24"
            viewBox="0 0 30 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_501_2707)">
                <path
                    d="M25 6.22217L12.5 6.22217"
                    stroke="#9AC9DB"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M25 12.0002L5 12.0002"
                    stroke="#9AC9DB"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M25 17.7781L17.5 17.7781"
                    stroke="#9AC9DB"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_501_2707">
                    <rect
                        width="23.112"
                        height="30"
                        fill="white"
                        transform="translate(0 23.5562) rotate(-90)"
                    />
                </clipPath>
            </defs>
        </svg>

        <svg
            width="30"
            height="24"
            viewBox="0 0 30 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_1001_3533)">
                <path
                    d="M8.74995 18.8533L22.8921 4.71118"
                    stroke="#9AC9DB"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M22.0711 19.0713L7.92896 4.9292"
                    stroke="#9AC9DB"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_1001_3533">
                    <rect
                        width="23.112"
                        height="30"
                        fill="white"
                        transform="translate(0 23.5562) rotate(-90)"
                    />
                </clipPath>
            </defs>
        </svg>
    </Btn>
);
