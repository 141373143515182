import CLink from '@/CLink';
import { info } from '@/layout/NavLinks';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import { smText } from './layout/styles/Typography';

const InfoLink = styled(CLink)`
    ${smText};
    margin-bottom: 18px;
    color: ${({ theme }) => theme.colors.light};
    display: flex;
    align-items: center;
    max-width: 346px;
    transition: color 0.3s ease-in-out;

    :hover,
    :focus {
        color: ${({ theme }) => theme.colors.accent};
    }

    :last-of-type {
        margin-bottom: 0;
    }

    > div:first-of-type {
        margin-right: 16px;
        flex-shrink: 0;

        img {
            filter: brightness(0) saturate(100%) invert(77%) sepia(15%) saturate(582%)
                hue-rotate(151deg) brightness(100%) contrast(87%);
        }
    }
`;

export const ContactInfo = () => {
    return (
        <div>
            <InfoLink to={info.address.link}>
                <StaticImage src="../../assets/icons/pin.svg" alt="address icon" layout="fixed" />
                {info.address.text}
            </InfoLink>
            <InfoLink to={info.email.link}>
                <StaticImage src="../../assets/icons/email.svg" alt="email icon" layout="fixed" />
                {info.email.text}
            </InfoLink>
            <InfoLink to={info.phone.link}>
                <StaticImage
                    src="../../assets/icons/phone.svg"
                    alt="phone icon"
                    layout="fixed"
                    width={16}
                    height={20}
                />
                {info.phone.text}
            </InfoLink>
        </div>
    );
};
