export const info = {
    email: {
        link: 'mailto:info@lovelacefamilydentistry.com',
        text: 'info@lovelacefamilydentistry.com',
    },
    phone: {
        link: 'tel:+19043450339',
        text: '(904)-345-0339',
    },
    address: {
        text: '4220 Valley Ridge Blvd Suite 101, Ponte Vedra Beach, FL 32081',
        link: 'https://goo.gl/maps/yMxf5Tscuz22HPNt7',
    },
    social: {
        facebook: 'https://www.facebook.com/LovelaceFamilyDentistry',
        instagram: 'https://www.instagram.com/lovelacefamilydentistry/',
    },
    booking: 'https://microsite.adit.com/c84c372f-5059-4881-bb39-8e3f52281f85',
};

export type NavLinksTypes = { text: string; link: string }[];

export const navLinks: NavLinksTypes = [
    {
        text: 'services',
        link: 'submenu',
    },
    {
        text: 'about us',
        link: '/about-us/',
    },
    {
        text: 'contact us',
        link: '/contact-us/',
    },
    // {
    //     text: 'patient education',
    //     link: '/patient-education/',
    // },
    // {
    //     text: 'our office',
    //     link: '/our-office/',
    // },
];

interface SubNavLinksProps {
    [index: string]: NavLinksTypes;
}

export const subNavLinks: SubNavLinksProps = {
    services: [
        {
            text: 'Cosmetic Dentistry',
            link: '/services/cosmetic-dentistry/',
        },
        {
            text: 'Periodontics',
            link: '/services/periodontics/',
        },
        {
            text: 'Pediatric Dentistry',
            link: '/services/pediatric-dentistry/',
        },
        {
            text: 'General Dentistry',
            link: '/services/general-dentistry/',
        },
        {
            text: 'Gentle Dentistry',
            link: '/services/gentle-dentistry/',
        },
        {
            text: 'Dental Images',
            link: '/services/dental-images/',
        },
        {
            text: 'Dentures',
            link: '/services/dentures/',
        },
        {
            text: 'Teeth Whitening',
            link: '/services/teeth-whitening/',
        },
        {
            text: 'Sedation Dentistry',
            link: '/services/sedation-dentistry/',
        },
        {
            text: 'oral health',
            link: '/services/oral-health/',
        },
        {
            text: 'sealants',
            link: '/services/sealants/',
        },
        {
            text: 'Endodontics',
            link: '/services/endodontics/',
        },
        {
            text: 'sedation dentistry',
            link: '/services/sedation-dentistry/',
        },
        {
            text: 'emergency dentistry',
            link: '/services/emergency-dentistry/',
        },
        {
            text: 'surgical dentistry',
            link: '/services/surgical-dentistry/',
        },
    ],
};

export const footerNavLinks: NavLinksTypes = [
    {
        text: 'about us',
        link: '/about-us/',
    },
    {
        text: 'contact us',
        link: '/contact-us/',
    },
];

export function getNavLink(text: string) {
    let filteredLink: { link: string; text: string }[] = [];

    const mainLink = navLinks.filter(link => link.text.toLowerCase() === text.toLowerCase());
    if (mainLink.length > 0) return mainLink[0].link;

    // incase category pages are part of subnavlinks
    // if (isCategory) {
    //     return subNavLinks[text][subNavLinks[text].length - 1].link;
    // }

    for (const key of Object.keys(subNavLinks)) {
        filteredLink = subNavLinks[key].filter(
            link => link.text.toLowerCase() === text.toLowerCase()
        );
        if (filteredLink.length > 0) break;
    }

    if (filteredLink.length === 0) return '';
    else return filteredLink[0].link;
}
