import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Menu, MenuButton, MenuItems, MenuLink, MenuPopover } from '@reach/menu-button';
import { Link } from 'gatsby';
import { Fragment, useEffect, useState } from 'react';
import { NavLinksTypes, subNavLinks } from './NavLinks';
import { navLinkStyles, StyledNavBtn, subNavLinkStyles } from './NavLinkStyles';

const StyledMenuPopover = styled(MenuPopover)`
    position: absolute;
    top: 175%;
    transform: translateX(-50%);
    left: 50%;
    z-index: 10;
    flex-direction: column;
    align-items: center;
    transition: transform 0.3s ease, opacity 0.3s ease;
    width: 100vw;

    &[hidden] {
        display: flex;

        &.mob-nav-dropdown-not-expanded {
            display: none;
        }
        &.mob-nav-dropdown-expanded {
            opacity: 0;
            transform: translateX(-50%) translateY(-20px);
        }
    }

    :not([hidden]) {
        &.mob-nav-dropdown-not-expanded {
            opacity: 0;
            transform: translateX(-50%) translateY(-20px);
            display: flex;
        }
        &.mob-nav-dropdown-expanded {
            opacity: 1;
            transform: translateX(-50%) translateY(0);
        }
    }

    [data-reach-menu-items] {
        background: ${({ theme }) => theme.colors.light};
        padding: 32px;
        display: grid;
        gap: 16px;
        justify-items: center;
        align-items: start;
        align-content: start;
        overflow-y: auto;

        @media (min-width: 500px) {
            max-width: fit-content;
            grid-template-rows: repeat(8, auto);
            grid-auto-flow: column;
            justify-items: start;
            margin: 0 auto;
            gap: 18px 32px;
        }

        /* reset */
        box-shadow: none;
        white-space: nowrap;
        outline: none;
        border: none;
    }

    [data-reach-menu-link] {
        ${navLinkStyles};
        ${subNavLinkStyles};
    }
`;

type PopoverProps = {
    isExpanded: boolean;
    links: NavLinksTypes;
    closeNav: () => void;
    navId: number;
};

const Popover = ({ isExpanded, links, closeNav, navId }: PopoverProps) => {
    const [expanded, setExpanded] = useState(isExpanded);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;

        if (isExpanded) {
            timeoutId = setTimeout(() => setExpanded(true), 10);
        } else if (!isExpanded) {
            timeoutId = setTimeout(() => setExpanded(false), 300);
        }
        return () => {
            clearTimeout(timeoutId);
        };
    }, [isExpanded]);

    return (
        <StyledMenuPopover
            className={expanded ? 'mob-nav-dropdown-expanded' : 'mob-nav-dropdown-not-expanded'}
            portal={false}
        >
            <MenuItems as="nav" aria-labelledby={`menu-button--mob-menu--${navId}`}>
                {links.map((link, i) => (
                    <MenuLink
                        key={i}
                        as={Link}
                        to={link.link}
                        activeClassName="nav-link-current-page"
                        onSelect={closeNav}
                    >
                        {link.text}
                    </MenuLink>
                ))}
            </MenuItems>
        </StyledMenuPopover>
    );
};

type MobSubNavProps = {
    text: string;
    closeNav: () => void;
    category: string;
    navId: number;
};

export const MobSubNav = ({ text, closeNav, category, navId }: MobSubNavProps) => {
    return (
        <div
            css={css`
                position: relative;
            `}
        >
            <Menu>
                {({ isExpanded }) => (
                    <Fragment>
                        <StyledNavBtn
                            as={MenuButton}
                            className={category === text ? 'nav-link-current-page' : ''}
                            id={`menu-button--mob-menu--${navId}`}
                        >
                            {text}
                        </StyledNavBtn>
                        <Popover
                            isExpanded={isExpanded}
                            links={subNavLinks[text]}
                            closeNav={closeNav}
                            navId={navId}
                        />
                    </Fragment>
                )}
            </Menu>
        </div>
    );
};
