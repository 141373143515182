import { GatsbyBrowser } from 'gatsby';
import { cloneElement } from 'react';
import { DefaultLayout } from './src/layouts/DefaultLayout';

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element, props }) => {
    if (element.type.Layout === false) {
        return cloneElement(element, props);
    }
    const Layout = element.type.Layout ?? DefaultLayout;
    return <Layout {...props}>{element}</Layout>;
};