import styled from '@emotion/styled';
import { ContactInfo } from './ContactInfo';
import { flexSpace, px } from './layout/styles/classes';
import { underline } from './layout/styles/Typography';

const Contact = styled.div`
    ${px};
    background-color: ${({ theme }) => theme.colors.dark};
    padding-top: 32px;
    padding-bottom: 32px;

    > h2 {
        ${underline};
        margin: 0 0 32px;
        color: ${({ theme }) => theme.colors.light};
    }

    @media (min-width: 1024px) {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-self: stretch;
    }
`;

const StyledHours = styled.div`
    max-width: 320px;
    z-index: 100;
    margin-top: 32px;
    padding: 8px;
    border: 1px solid ${({ theme }) => theme.colors.accent};

    > p {
        ${flexSpace};
        margin: 0 0 8px;
        color: ${({ theme }) => theme.colors.light};
        text-transform: capitalize;

        :last-of-type {
            margin: 0;
        }

        span:first-of-type {
            font-weight: 700;
            text-transform: uppercase;
        }
    }
`;

export const ContactHours = () => {
    return (
        <Contact>
            <h2>Contact us & Hours</h2>
            <ContactInfo />
            <StyledHours>
                <p>
                    <span>Monday</span>
                    <span>07:30AM - 05:00PM</span>
                </p>
                <p>
                    <span>Tuesday</span>
                    <span>07:30AM - 05:00PM</span>
                </p>
                <p>
                    <span>Wednesday</span>
                    <span>07:30AM - 05:00PM</span>
                </p>
                <p>
                    <span>Thursday</span>
                    <span>07:30AM - 05:00PM</span>
                </p>
                <p>
                    <span>Friday</span>
                    <span>08:00AM - 012:00PM</span>
                </p>
                <p>
                    <span>Saturday</span>
                    <span>Appointments Only</span>
                </p>
                <p>
                    <span>Sunday</span>
                    <span>Appointments Only</span>
                </p>
            </StyledHours>
        </Contact>
    );
};
