import { ArticleBg } from '@/ArticleBg';
import { StaticImage } from 'gatsby-plugin-image';

export const Office = () => {
    return (
        <ArticleBg right="true">
            <StaticImage
                src="../../../assets/images/layout/office.jpg"
                alt="outside office"
                layout="fullWidth"
            />

            <div>
                <h2>our office</h2>
                <p>
                    At Lovelace Family Dentistry, we combine the highest quality dental care with an
                    environment where patients will feel at home to provide the best experience
                    possible.
                </p>

                {/* <PrimaryBtn as={CLink} to={getNavLink('contact us')}>
                    Virtual Tour
                </PrimaryBtn> */}
            </div>
        </ArticleBg>
    );
};
