import { css } from '@emotion/react';

export const px = css`
    padding-left: 16px;
    padding-right: 16px;

    @media (min-width: 768px) {
        padding-left: 32px;
        padding-right: 32px;
    }

    @media (min-width: 1280px) {
        padding-left: 64px;
        padding-right: 64px;
    }
`;

export const flexCenter = css`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const flexSpace = css`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const flexStart = css`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
`;

export const linkHover = css`
    transition: opacity 0.3s ease-in-out;

    &:focus-visible {
        opacity: 0.7;
    }

    &:hover {
        opacity: 0.7;
    }
`;
