import { flexCenter } from '@/layout/styles/classes';
import { smText } from '@/layout/styles/Typography';
import arrow from '@a/icons/arrow-right.svg';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import PlainBtn from './PlainBtn';

export default styled(PlainBtn)<{ dark?: string; border?: string }>`
    ${flexCenter};
    ${smText};
    display: inline-flex;
    font-weight: 500;
    padding: 12px 24px;
    border-radius: 64px;
    color: ${({ theme, dark }) => (dark ? theme.colors.accentDark : theme.colors.light)};
    position: relative;
    box-shadow: ${({ border, theme, dark }) =>
        border ? 'none' : `inset 0 0 0 1px ${dark ? theme.colors.accentDark : theme.colors.light}`};

    ::after {
        content: url(${arrow});
        margin-left: 16px;

        ${({ dark }) =>
            dark
                ? css`
                      filter: brightness(0) saturate(100%) invert(22%) sepia(98%) saturate(389%)
                          hue-rotate(135deg) brightness(101%) contrast(95%); ;
                  `
                : ''};
    }

    :hover,
    :focus-visible {
        ::after {
            animation: translateBtnArrow 0.9s ease-out infinite;
        }
    }

    @keyframes translateBtnArrow {
        33% {
            transform: translateX(-4px);
        }

        66% {
            transform: translateX(8px);
        }

        100% {
            transform: translateX(0);
        }
    }
`;
