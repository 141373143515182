import styled from '@emotion/styled';
import { underline } from './layout/styles/Typography';

export const ArticleBg = styled.article<{ right?: string }>`
    color: ${({ theme }) => theme.colors.white};
    display: grid;
    position: relative;

    ::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 2;
    }

    > div {
        grid-area: 1/1;
        z-index: 1;

        &:not(.gatsby-image-wrapper) {
            padding: 72px 16px;
            max-width: 560px;
            z-index: 3;
            align-self: center;

            > h2 {
                ${underline};
                margin: 0 0 32px;
                text-transform: uppercase;
            }

            > p {
                margin: 32px 0;
                font-weight: 700;

                :first-of-type {
                    margin-top: 0;
                }

                :last-of-type {
                    margin-bottom: 0;
                }
            }

            > button,
            > a {
                margin-top: 32px;
            }
        }
    }

    @media (min-width: 768px) {
        > div:not(.gatsby-image-wrapper) {
            padding: 72px 0;
            max-width: 528px;
            margin: ${({ right }) => (right ? '0 10% 0 0' : '0 0 0 10%')};
            justify-self: ${({ right }) => (right ? 'end' : 'unset')};
        }
    }

    @media (min-width: 1024px) {
        //assumes content on desktop wont take more than 500px i.e. 144px padding + 500px
        height: 644px;
    }

    @media (min-width: 1600px) {
        > div:not(.gatsby-image-wrapper) {
            margin: ${({ right }) => (right ? '0 17% 0 0' : '0 0 0 17%')};
        }
    }
`;
