import { ContactAppointment } from '@/layout/ContactAppointment';
import { Footer } from '@/layout/Footer';
import { Map } from '@/layout/Map';
import { Nav } from '@/layout/Nav';
import { Office } from '@/layout/Office';
import { Seo } from '@/layout/Seo';
import { GlobalStyles, theme } from '@/layout/styles/GlobalStyles';
import { ThemeProvider } from '@emotion/react';
import { SkipNavContent } from '@reach/skip-nav';
import { ReactNode } from 'react';

export const DefaultLayout = ({ children }: { children: ReactNode }) => {
    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles />
            <Seo />
            <Nav />
            <SkipNavContent />
            <main>{children}</main>
            <Office />
            <ContactAppointment />
            <Map />
            <Footer />
        </ThemeProvider>
    );
};
