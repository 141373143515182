import { css, Global, Theme } from '@emotion/react';
import '@fontsource/montserrat';
import '@fontsource/montserrat/400-italic.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/700.css';
import emotionNormalize from 'emotion-normalize';
import { h1, h2, h3, underline } from './Typography';

export const theme: Theme = {
    colors: {
        white: '#fff',
        light: '#FFF6EA',
        dark: '#282120',
        accent: '#9AC9DB',
        accentDark: '#0C585D',
        lightGray: '#737373',
        gradient: 'radial-gradient(100% 100% at 0% 0%, #00819D 0%, #0C585D 100%)',
    },
    spacing: {
        md: '2.22vw', //32px at 1440px wide viewport
        lg: '4.45vw', //64px ^^
        xl: '6.67vw', //96px ^^
        xxl: '8.89vw', //128px ^^
    },
};

export const GlobalStyles = () => {
    return (
        <Global
            styles={theme => css`
                ${emotionNormalize};

                html {
                    font-size: 100%;
                    overflow-x: hidden;
                    scroll-behavior: smooth;
                }
                body {
                    color: ${theme.colors.dark};
                    font-family: 'Montserrat', sans-serif;
                    font-weight: normal;
                    font-size: 1rem;
                    line-height: 162%;
                    word-wrap: break-word;
                    font-kerning: normal;
                    overflow-x: hidden;
                    word-wrap: break-word;
                    -webkit-font-smoothing: antialiased;
                }

                h1 {
                    ${h1};
                }

                h2 {
                    ${h2};
                }

                h3 {
                    ${h3};
                }

                h1,
                h2 {
                    ${underline};
                }

                a {
                    text-decoration: none;
                    color: ${theme.colors.dark};
                    outline: none;
                    background-color: transparent;
                    -webkit-text-decoration-skip: objects;
                }

                *,
                *:before,
                *:after {
                    box-sizing: border-box;
                }

                img {
                    max-width: 100%;
                    display: block;
                    margin: 0;
                    padding: 0;
                }

                ul,
                ol {
                    padding-inline-start: 24px;
                }
            `}
        />
    );
};
