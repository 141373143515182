import { PlainBtn } from '@/buttons';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { flexCenter } from './styles/classes';
import { theme } from './styles/GlobalStyles';
import { smText } from './styles/Typography';

export const navLinkStyles = css`
    ${smText};
    text-transform: uppercase;
    position: relative;
    font-weight: 500;
    color: ${theme.colors.dark};
    padding: 16px 32px 12px;
    border-bottom: 4px solid transparent;
    transition: border-color 0.6s ease-in-out, background-color 0.6s ease-in-out;

    &[aria-expanded='true'],
    &[data-selected],
    :hover {
        border-color: ${theme.colors.accent};
        background-color: ${theme.colors.light};
    }

    :focus-visible {
        border-color: ${theme.colors.accent};
        background-color: ${theme.colors.light};
    }

    &.nav-link-current-page {
        border-color: ${theme.colors.accent};
    }
`;

export const subNavLinkStyles = css`
    font-weight: 500;
    text-transform: capitalize;
    border-bottom-width: 2px;
    transition: color 0.6s ease-in-out, border-color 0.6s ease-in-out;
    line-height: 122%;

    &[data-selected],
    :hover {
        color: ${theme.colors.accentDark};
    }

    :focus-visible {
        color: ${theme.colors.accentDark};
    }

    /* reset */
    padding: 0 0 4px 0;
`;

export const StyledNavBtn = styled(PlainBtn)`
    ${flexCenter};
    ${navLinkStyles};

    ::after {
        content: '';
        height: 7px;
        width: 7px;
        border-right: 2px solid ${theme.colors.dark};
        border-bottom: 2px solid ${theme.colors.dark};
        transform: rotate(45deg) translateY(-4px);
        margin-left: 14px;
        margin-right: 3px;
        transition: transform 0.3s ease-in-out;
    }

    &[aria-expanded='true'] {
        ::after {
            transform: rotate(225deg) translateY(0);
        }
    }
`;
