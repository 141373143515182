import { css } from '@emotion/react';

export const h1 = css`
    font-size: 2.25rem;
    line-height: 162%;
    font-weight: 500;
    text-transform: capitalize;

    @media (min-width: 1024px) {
        font-size: 4rem;
    }
`;

export const h2 = css`
    font-size: 2.25rem;
    line-height: 162%;
    font-weight: 500;
`;

export const h3 = css`
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 162%;
    text-transform: capitalize;
`;

export const smText = css`
    font-size: 0.875rem;
    line-height: 162%;
    letter-spacing: 0.2px;
`;

export const lgText = css`
    font-size: 1.125rem;
    line-height: 162%;
`;

export const underline = css`
    position: relative;
    padding-bottom: 8px;

    ::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 146px;
        height: 8px;
        background-color: #9ac9db;
        box-shadow: 0px 8px 32px -2px #00f5ff;
    }
`;

export const whiteUnderline = css`
    ${underline};

    ::after {
        background-color: #fff;
        box-shadow: 0px 8px 32px -2px #fff;
    }
`;

export const hideUnderline = css`
    padding-bottom: 0;
    position: unset;

    ::after {
        display: none;
    }
`;
