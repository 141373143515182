import styled from '@emotion/styled';
import { flexCenter } from './layout/styles/classes';
import { lgText, smText } from './layout/styles/Typography';

export const Input = styled.input<{ rows?: number }>`
    border-radius: 5px;
    width: 100%;
    padding: 11px 21px;
    display: block;
    outline: none;
    border: none;
    transition: box-shadow 0.3s ease-in;
    margin-bottom: 10px;
    color: ${({ theme }) => theme.colors.accentDark};
    font-size: 0.875rem;
    line-height: 200%;
    letter-spacing: 0.2px;
    background-color: ${({ theme }) => theme.colors.white};

    :hover,
    :focus-visible {
        box-shadow: inset 0 0 0 1px ${({ theme }) => theme.colors.accentDark};
    }

    ::placeholder {
        color: ${({ theme }) => theme.colors.lightGray};
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        text-fill-color: ${({ theme }) => theme.colors.accentDark};
    }

    :focus:invalid {
        box-shadow: inset 0 0 0 1px #e75842;
    }
`;

export const Input2 = styled(Input)`
    box-shadow: inset 0 0 0 1px #e6e6e6;
    background-color: #f9f9f9;
`;

export const Label = styled.label`
    ${smText};
    line-height: 171%;
    color: ${({ theme }) => theme.colors.dark};
    margin-bottom: 10px;
    display: block;
    font-weight: 500;
    text-transform: capitalize;
`;

const StyledThanks = styled.div<{ submit: boolean }>`
    ${flexCenter};
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 32px;
    text-align: center;
    z-index: ${({ submit }) => (submit ? 10 : -1)};
    opacity: ${({ submit }) => (submit ? 1 : 0)};
    transform: ${({ submit }) => (submit ? 'scale(1)' : 'scale(0)')};
    transition: all 0.3s ease-in;
    transition-property: opacity, transform;
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.accentDark};

    > h2 {
        margin-top: 0;
        font-size: 2rem;
    }

    > p {
        margin-bottom: 0;
        ${lgText};
    }

    @media (min-width: 768px) {
        padding: 64px;
    }
`;

export const Thanks = ({ submit }: { submit: boolean }) => (
    <StyledThanks submit={submit}>
        <h2>Thank you</h2>
        <p>Someone will be right with you shortly!</p>
    </StyledThanks>
);
