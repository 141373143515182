import CLink from '@/CLink';
import { footerNavLinks, info, subNavLinks } from '@/layout/NavLinks';
import docLogo from '@a/images/layout/doc-logo.svg';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import { flexCenter, flexSpace, flexStart, px } from './styles/classes';

const StyledFooter = styled.footer`
    ${px};
    padding-top: 32px;
    padding-bottom: 32px;
    background-color: ${({ theme }) => theme.colors.dark};
    color: ${({ theme }) => theme.colors.light};

    @media (min-width: 1280px) {
        padding-top: 64px;
        padding-bottom: 64px;
    }
`;

const Flex = styled.div`
    ${flexCenter};
    flex-direction: column;
    max-width: 1275px;
    margin: 0 auto;
    padding: 0 32px;

    @media (min-width: 1024px) {
        flex-direction: row;
    }

    @media (min-width: 1280px) {
        padding: 0;
    }
`;

const LinksContainer = styled.div`
    max-width: 200px;

    @media (min-width: 768px) {
        ${flexCenter};
        align-items: flex-start;
        max-width: unset;
        margin-top: 64px;
    }

    @media (min-width: 1024px) {
        margin-left: ${({ theme }) => theme.spacing.xxl};

        > .gatsby-image-wrapper {
            align-self: center;
            flex-shrink: 5;
        }
    }

    @media (min-width: 1280px) {
        margin-left: 17.77vw; //256px at 1440
        flex-shrink: 0;
    }
`;

const FooterLinks = styled.div`
    :first-of-type {
        margin: 32px 0;
    }

    > h5 {
        font-size: 1rem;
        line-height: 162%;
        font-weight: 700;
        text-transform: uppercase;
        margin: 0 0 12px;
        min-width: 128px;
    }

    > a {
        ${flexStart};
        margin-bottom: 12px;
        color: ${({ theme }) => theme.colors.light};
        transition: color 0.3s ease-in-out;
        text-transform: capitalize;

        :hover,
        :focus {
            color: ${({ theme }) => theme.colors.accent};
        }

        :last-of-type {
            margin-bottom: 0;
        }
    }

    @media (min-width: 768px) {
        flex-shrink: 0;

        :first-of-type {
            margin: 0 ${({ theme }) => theme.spacing.xxl} 0 0;
        }
    }
`;

const Icons = styled.div`
    max-width: 230px;
    margin: 32px auto;

    > p {
        font-weight: 700;
        text-transform: uppercase;
        margin: 0 0 24px;
    }

    > div {
        ${flexSpace};
        justify-content: space-around;

        a {
            line-height: 0;
            path {
                transition: fill 0.3s ease-in-out;
            }

            :hover,
            :focus {
                path {
                    fill: ${({ theme }) => theme.colors.light};
                }
            }
        }
    }

    @media (min-width: 1280px) {
        margin-top: 64px;
    }
`;

const Box = styled.div`
    margin: 32px auto;
    max-width: 433px;

    > p {
        margin: 0 0 12px;
        text-align: center;
    }

    > div {
        ${flexSpace};
        max-width: 267px;
        margin: 0 auto;

        a {
            text-transform: uppercase;
            color: ${({ theme }) => theme.colors.light};
            transition: color 0.3s ease-in-out;

            :hover,
            :focus {
                color: ${({ theme }) => theme.colors.accent};
            }
        }
    }

    @media (min-width: 1280px) {
        margin: 64px auto;
    }
`;

const Power = styled.div`
    ${flexCenter};
    position: relative;
    padding-top: 32px;

    ::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 1px;
        background-image: linear-gradient(
            270deg,
            rgba(0, 0, 0, 0) 0%,
            #9ac9db 53.13%,
            rgba(0, 0, 0, 0) 100%
        );
    }
    > a {
        width: 26px;
        height: 35px;
        -webkit-mask-image: url(${docLogo});
        mask-image: url(${docLogo});
        margin-right: 32px;
        background-color: ${({ theme }) => theme.colors.light};
        transition: background-color 0.3s ease-in-out;

        span {
            width: 0;
            height: 0;
            visibility: hidden;
        }

        :hover,
        :focus {
            background-color: ${({ theme }) => theme.colors.accent};
        }
    }

    > p {
        text-transform: uppercase;
        margin: 0;

        a {
            color: ${({ theme }) => theme.colors.light};
            transition: color 0.3s ease-in-out;

            :hover,
            :focus {
                color: ${({ theme }) => theme.colors.accent};
            }
        }
    }
`;

export const Footer = () => {
    return (
        <StyledFooter>
            <Flex>
                <StaticImage
                    src="../../../assets/images/layout/big-white-logo.svg"
                    alt="lovelace family dentistry"
                />
                <LinksContainer>
                    <FooterLinks>
                        <h5>services</h5>
                        {subNavLinks.services.map((link, i) => (
                            <CLink to={link.link} key={i}>
                                {link.text}
                            </CLink>
                        ))}
                    </FooterLinks>

                    <FooterLinks>
                        <h5>resources</h5>
                        {footerNavLinks.map((link, i) => (
                            <CLink to={link.link} key={i}>
                                {link.text}
                            </CLink>
                        ))}
                    </FooterLinks>
                </LinksContainer>
            </Flex>

            <Icons>
                <p>FIND US ON SOCIAL MEDIA</p>
                <div>
                    <CLink to={info.social.facebook} aria-label="Lovelace dental facebook">
                        {/* svgr next time */}
                        <svg
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M20 0C8.97207 0 0 8.97207 0 20C0 31.0279 8.97207 40 20 40C31.0279 40 40 31.0279 40 20C40 8.97207 31.0279 0 20 0ZM20 3C29.4066 3 37 10.5934 37 20C37 28.3808 30.9672 35.3064 23 36.7207V25H26.625C27.129 25 27.5552 24.623 27.6172 24.123L27.9922 21.123C28.0282 20.839 27.938 20.5539 27.748 20.3379C27.559 20.1229 27.287 20 27 20H23V16.5C23 15.397 23.897 14.5 25 14.5H27C27.552 14.5 28 14.053 28 13.5V10.125C28 9.607 27.6048 9.17491 27.0898 9.12891C27.0308 9.12391 25.62 9 23.834 9C19.427 9 17 11.6162 17 16.3672V20H13C12.448 20 12 20.447 12 21V24C12 24.553 12.448 25 13 25H17V36.7207C9.03282 35.3064 3 28.3808 3 20C3 10.5934 10.5934 3 20 3Z"
                                fill="#9AC9DB"
                            />
                        </svg>
                    </CLink>
                    <CLink to={info.social.instagram} aria-label="Lovelace dental instagram">
                        <svg
                            width="38"
                            height="38"
                            viewBox="0 0 38 38"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M11.5 0C5.16639 0 0 5.16639 0 11.5V26.5C0 32.8328 5.16621 38 11.5 38H26.5C32.8329 38 38 32.8329 38 26.5V11.5C38 5.16621 32.8328 0 26.5 0H11.5ZM11.5 3H26.5C31.2112 3 35 6.78779 35 11.5V26.5C35 31.2111 31.2111 35 26.5 35H11.5C6.78779 35 3 31.2112 3 26.5V11.5C3 6.78761 6.78761 3 11.5 3ZM29 7C27.895 7 27 7.895 27 9C27 10.105 27.895 11 29 11C30.105 11 31 10.105 31 9C31 7.895 30.105 7 29 7ZM19 9C13.4952 9 9 13.4952 9 19C9 24.5048 13.4952 29 19 29C24.5048 29 29 24.5048 29 19C29 13.4952 24.5048 9 19 9ZM19 12C22.8832 12 26 15.1168 26 19C26 22.8832 22.8832 26 19 26C15.1168 26 12 22.8832 12 19C12 15.1168 15.1168 12 19 12Z"
                                fill="#9AC9DB"
                            />
                        </svg>
                    </CLink>
                </div>
            </Icons>

            <Box>
                <p>Lovelace Family Dentistry. All Rights Reserved. © {new Date().getFullYear()}</p>
                <div>
                    <CLink to="/privacy-policy">privacy policy</CLink>
                    <CLink to="">sitemap</CLink>
                </div>
            </Box>

            <Power>
                <CLink to="https://moderndocmedia.com" aria-label="MODERN DOC MEDIA" />
                <p>
                    POWERED BY <CLink to="https://moderndocmedia.com">MODERN DOC MEDIA</CLink>.
                </p>
            </Power>
        </StyledFooter>
    );
};
