import { flexCenter } from '@/layout/styles/classes';
import styled from '@emotion/styled';
import PlainBtn from './PlainBtn';

export default styled(PlainBtn)`
    ${flexCenter};
    display: inline-flex;
    border-radius: 50%;
    position: relative;
    z-index: 1;
    transition: box-shadow 0.3s ease-in-out;
    box-shadow: inset 0 0 0 2px ${({ theme }) => theme.colors.accent};
    width: 48px;
    height: 48px;

    :hover {
        box-shadow: inset 0 0 0 2px ${({ theme }) => theme.colors.accentDark};
    }

    :focus-visible {
        box-shadow: inset 0 0 0 2px ${({ theme }) => theme.colors.accentDark};
    }
`;
