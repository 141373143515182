type Seo = {
    [index: string]: {
        t: string;
        d: string;
    };
};

export const seoData: Seo = {
    'meet-our-family': {
        t: ' Meet Our Family - Lovelace Family Dentistry',
        d: 'Meet the Lovelace Family Dentistry team.',
    },
    'about-us': {
        t: 'About Lovelace Family Dentistry',
        d: 'With modern technology and preventative care, Lovelace Family Dentistry assures a smile that you can be proud of. Read more about Lovelace Family Dentistry.',
    },

    'contact-us': {
        t: 'Contact Lovelace Family Dentistry',
        d: 'Find quality dentistry services in Florida at Lovelace Family Dentistry. We specialize in providing full-service dental care. Call us at +19043450339.',
    },
    'general-dentistry': {
        t: 'Dentistry Services in Florida - Lovelace Family Dentistry',
        d: 'Lovelace Family Dentistry provides a complete range of general Dentistry Services in Florida. We offer proven treatments, new technology, and quality dentistry.',
    },

    'cosmetic-dentistry': {
        t: 'Cosmetic Dentistry Service in Fl - Lovelace Family Dentistry',
        d: 'At Lovelace Family Dentistry, we help you to enhance the appearance of your smile with Cosmetic Dentistry Services in FL. Book an Appointment today!',
    },

    'pediatric-dentistry': {
        t: 'Pediatric Dentistry Services in Florida',
        d: 'Find quality Pediatric Dentistry Services in Florida at Lovelace Family Dentistry. We specialize in providing full-service dental care. Call us at +19043450339.',
    },

    periodontics: {
        t: 'Periodontics Services in Florida',
        d: 'Find quality Periodontics Services in Florida at Lovelace Family Dentistry. We specialize in providing full-service dental care. Call us at +19043450339.',
    },

    'dental-images': {
        t: 'Dental Imaging Services in FL - Lovelace Family Dentistry',
        d: "It's essential to have good oral and dental hygiene. To maintain healthy teeth you can have Dental Imaging Services in Florida at Lovelace Family Dentistry.",
    },

    dentures: {
        t: 'Dentures Services in Florida - Lovelace Family Dentistry',
        d: 'Lovelace Family Dentistry provides personalized Dentures Services in Florida that meet the needs of our patients. We offer a wide range of dental services.',
    },

    'teeth-whitening': {
        t: 'Teeth whitening Treatment in Ponte Vedra Beach, FL',
        d: 'Get Teeth whitening Treatment in Ponte Vedra Beach, FL at Lovelace Family Dentistry. We offer proven treatments, new technology, and quality dentistry. Call us!',
    },

    'sedation-dentistry': {
        t: 'Sedation Dentistry Services in Florida',
        d: 'Lovelace Family Dentistry has been providing Sedation Dentistry Services in Florida. We offer the highest quality dental care. Book an appointment today!',
    },

    invisalign: {
        t: 'Invisalign Treatment Services in Ponte Vedra Beach, FL',
        d: 'Enhance the appearance of your smile with Invisalign Treatment Services in FL at Lovelace Family Dentistry. We offer a wide range of dental services. Call us.',
    },

    sealants: {
        t: 'Dental Sealants Treatment in Florida',
        d: 'Dental sealants help protect your teeth from damage and decay. At Lovelace Family Dentistry, we offer quality Dental Sealants Treatment in Florida. Contact us!',
    },

    'smile-makeover': {
        t: 'Smile Makeover Treatment in Ponte Vedra Beach, FL',
        d: 'At Lovelace Family Dentistry, Dr. Joshua Lovelace offers Smile Makeover Treatment in Florida to patients. We help you to improve your smile appearance. Call us!',
    },

    'tmj-treatment': {
        t: 'TMJ Treatment Services in FL - Lovelace Family Dentistry',
        d: "Lovelace Family Dentistry offers TMJ Treatments Services in FL that can help you relieve pain. We give the utmost care by listening to the patient's needs. Call us!",
    },

    'emergency-dentistry': {
        t: ' Emergency Dentistry Services in Florida',
        d: 'For emergency dentistry services in Florida, contact Lovelace Family Dentistry! We offer proven treatments, new technology, and quality dentistry. Contact us.',
    },

    'gentle-dentistry': {
        t: 'Gentle Dentistry Ponte Vedra Beach FL',
        d: 'Gentle Dentistry was created in response to people with anxiety or fear around seeking dental care.',
    },

    'surgical-dentistry': {
        t: 'Surgical Dentistry Ponte Vedra Beach FL',
        d: 'Lovelace Family Dentistry provides comprehensive dental services, including surgical interventions for dental problems requiring this level of care.',
    },

    endodontics: {
        t: 'Endodontics Ponte Vedra Beach FL',
        d: 'We provide oral surgery/endodontic treatments including root canal, wisdom teeth removal, dental implants, & tooth extraction.',
    },

    'oral-health': {
        t: 'Oral Health Services Ponte Vedra Beach FL',
        d: 'Oral health is essential to general health and well-being.  Help is available if you are a Ponte Vedra Beach resident needing dental care.',
    },
};
