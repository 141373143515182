import { IconBtn, PlainBtn } from '@/buttons';
import CLink from '@/CLink';
import { info, navLinks } from '@/layout/NavLinks';
import address from '@a/icons/address.svg';
import phone from '@a/icons/phone.svg';
import styled from '@emotion/styled';
import '@reach/menu-button/styles.css';
import { SkipNavLink } from '@reach/skip-nav';
import '@reach/skip-nav/styles.css';
import useLocationData from '@s/hooks/useLocationData';
import { Fragment, useState } from 'react';
import logo from '../../../assets/images/layout/logo.svg';
import { DesktopSubNav } from './DesktopSubNav';
import { MobNav, MobNavBtn } from './MobNav';
import { navLinkStyles } from './NavLinkStyles';
import { flexCenter, flexSpace, linkHover } from './styles/classes';

const Header = styled.header`
    ${flexSpace};
    padding: 16px;
    margin: 0 auto;
    z-index: 100;

    // for homepage since gatsby image has position and z-index
    position: relative;

    background-color: ${({ theme }) => theme.colors.light};

    @media (min-width: 1024px) {
        padding: 16px 32px;
        background-color: rgba(255, 255, 255, 0.8);
        backdrop-filter: blur(16px);

        @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
            background-color: rgba(255, 255, 255, 0.9);
        }
    }

    @media (min-width: 1920px) {
        padding: 16px calc((100vw - 1856px) / 2);
    }
`;

const StyledLink = styled(CLink)`
    ${linkHover};

    @media (max-width: 1023px) {
        > img {
            width: 107px;
            height: 44px;
        }
    }

    @media (min-width: 1024px) {
        padding-right: 28px;
        border-right: 1px solid ${({ theme }) => theme.colors.white};
    }
`;

const DesktopNav = styled.nav`
    display: none;

    @media (min-width: 1024px) {
        display: flex;
    }
`;

const Btns = styled.div`
    ${flexCenter};
    padding-left: 28px;
    border-left: 1px solid ${({ theme }) => theme.colors.white};
    align-self: stretch;

    > a {
        margin-left: 32px;

        :first-of-type {
            margin: 0;
        }

        :last-of-type {
            display: none;
        }
    }

    @media (max-width: 1279px) {
        display: none;
    }

    @media (min-width: 600px) {
        > a:last-of-type {
            display: block;
        }
    }
`;

const AppointmentBtn = styled(PlainBtn)`
    padding: 8px 16px;
    border-radius: 32px;
    font-weight: 500;
    position: relative;
    z-index: 1;
    transition: color 0.3s ease-in-out;
    color: ${({ theme }) => theme.colors.light};
    line-height: 162%;

    ::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: ${({ theme }) => theme.colors.gradient};
        opacity: 1;
        transition: opacity 0.3s ease-in-out;
        border-radius: 32px;
        z-index: -1;
    }

    :hover {
        box-shadow: inset 0 0 0 2px ${({ theme }) => theme.colors.dark};
        color: ${({ theme }) => theme.colors.dark};

        ::after {
            opacity: 0;
        }
    }

    :focus-visible {
        box-shadow: inset 0 0 0 2px ${({ theme }) => theme.colors.dark};
        color: ${({ theme }) => theme.colors.dark};

        ::after {
            opacity: 0;
        }
    }
`;

export const Nav = () => {
    const { category } = useLocationData();

    // for mob nav
    const [navOpen, setNavOpen] = useState(false);
    const [navVisibility, setNavVisibility] = useState(false);

    const closeNav = () => {
        setNavOpen(false);
        setTimeout(() => setNavVisibility(false), 600);
    };

    const toggleNav = () => {
        if (navOpen) {
            closeNav();
        } else {
            setNavVisibility(true);
            setTimeout(() => setNavOpen(true), 10);
        }
    };

    return (
        <Fragment>
            <Header>
                <StyledLink to="/">
                    <img src={logo} alt="lovelace dental" width={154} height={64} />
                </StyledLink>
                <SkipNavLink />
                <MobNavBtn toggleNav={toggleNav} navOpen={navOpen} />
                <DesktopNav>
                    {navLinks.map((link, i) =>
                        link.link === 'submenu' ? (
                            <DesktopSubNav text={link.text} category={category} key={i} navId={i} />
                        ) : (
                            <CLink
                                to={link.link}
                                activeClassName="nav-link-current-page"
                                css={navLinkStyles}
                                key={i}
                            >
                                {link.text}
                            </CLink>
                        )
                    )}
                </DesktopNav>

                <Btns>
                    <IconBtn as={CLink} to="#location" aria-label={info.address.text}>
                        <img src={address} alt="address" width={24} height={25} />
                    </IconBtn>
                    <IconBtn as={CLink} to={info.phone.link} aria-label={info.phone.text}>
                        <img src={phone} alt="phone" width={20} height={28} />
                    </IconBtn>
                    <AppointmentBtn as={CLink} to="#appointment">
                        Book an Appointment
                    </AppointmentBtn>
                </Btns>
            </Header>
            <MobNav
                setNavOpen={setNavOpen}
                navOpen={navOpen}
                navVisibility={navVisibility}
                closeNav={closeNav}
                category={category}
            />
        </Fragment>
    );
};
