import { PrimaryBtn } from '@/buttons';
import { Input, Label, Thanks } from '@/FormComponents';
import styled from '@emotion/styled';
import { useState } from 'react';
import NumberFormat from 'react-number-format';

const Form = styled.form`
    max-width: 400px;
    position: relative;
    scroll-margin-top: 20px;

    > h2 {
        color: ${({ theme }) => theme.colors.dark};
        margin: 0 0 32px;
    }

    > button:last-of-type {
        margin-top: 8px;
        color: ${({ theme }) => theme.colors.dark};
        box-shadow: inset 0 0 0 1px ${({ theme }) => theme.colors.dark};

        ::after {
            filter: brightness(0) saturate(100%) invert(21%) sepia(96%) saturate(433%)
                hue-rotate(144deg) brightness(92%) contrast(93%);
        }
    }

    @media (min-width: 768px) {
        width: 400px;
    }
`;

export const ContactForm = () => {
    const [submit, setSubmit] = useState(false);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const data = Object.fromEntries(new FormData(e.currentTarget));

        await fetch(`https://api.${window.location.host}/contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        setSubmit(true);
    };

    return (
        <Form onSubmit={handleSubmit} id="contact">
            <h2>Get in touch</h2>

            <Label htmlFor="name">Name*</Label>
            <Input type="text" id="name" name="name" placeholder="Name" required />

            <Label htmlFor="phone">Phone*</Label>
            <NumberFormat
                type="tel"
                format="+1 (###) ###-####"
                customInput={Input}
                placeholder="123 456-7890"
                id="phone"
                name="phone"
                required
            />

            <Label htmlFor="email">Email address*</Label>
            <Input
                type="email"
                id="email"
                name="email"
                required
                placeholder="e.g: example@gmail.com"
            />

            <Label htmlFor="message">Notes for the Doctor</Label>
            <Input
                as="textarea"
                rows={3}
                id="message"
                name="message"
                placeholder="Write your message here"
            />

            <PrimaryBtn>Get in touch</PrimaryBtn>
            <Thanks submit={submit} />
        </Form>
    );
};
