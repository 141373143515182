import styled from '@emotion/styled';

const Iframe = styled.iframe`
    width: 100%;
    height: 450px;
    border: 0;
    margin-bottom: -8px;

    @media (min-width: 1024px) {
        height: 700px;
    }
`;

export const Map = () => {
    return (
        <Iframe
            title="lovelace family dentistry location on map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3450.9482910899455!2d-81.4283112!3d30.1242931!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88e433f80c84d52b%3A0x73c7c6a0e58e88d9!2sLovelace%20Family%20Dentistry!5e0!3m2!1sen!2s!4v1659474204543!5m2!1sen!2s"
            allowFullScreen
            loading="lazy"
            id="map"
        ></Iframe>
    );
};
